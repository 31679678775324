<template>
  <div>
       <Spinner />
       <SubHeaderBlock 
       :drivers = items 
       :count=count
       :page-number=pageNumber
       @nextPage = "IncPageNum"
       @prevPage = "DecPageNum"
       />
       <FilterRoutes  
       @getDriversList="getDriversList" 
       @getUserByCityId="getUserByCityId" 
       @getUserByCityAndStatus = "getUserByCityAndStatus"
       @getUserByStatus ="getUserByStatus" 
       />
       <TableBlock v-if="items" :items = items />
       <!-- <Modal 
       v-if="showModal" 
       @showModal = 'changeShowModal'
       /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spinner from "../../components/spinner/index.vue";

export default {
   components: {
    TableBlock: () => ({
      component: import("./components/table"),
    }),
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    FilterRoutes: () => ({
      component: import("./components/filters.vue"),
    }),
    Spinner
  },
  data() {
    return {
      showModal : false,
      items : null,
      pageNumber: 1,
      count: 0
    }
  },
  computed: {
    ...mapState(['driversList']),
  },
  methods: {
    changeShowModal() {
      this.showModal = !this.showModal
    },
    IncPageNum(){
        this.pageNumber++
      },
    DecPageNum(){
        this.pageNumber--;
      },
    async getDriversList(){
      await this.$store.dispatch("getDriversList");
      this.items = this.driversList.data.slice(0, 10);
      this.count = this.driversList.data.length
    },

    getUserByCityId(data){
      this.items = [];
        this.driversList.data.forEach(element => {
            if(data.cityId == element.city){
              this.items.push(element)
            }
        });
        this.count = this.items.length 
    },

    getUserByCityAndStatus(data){
        this.items = [];
        this.driversList.data.forEach(element => {
            if(data.cityId == element.city && data.status == element.work_status){
              this.items.push(element)
            }
        });
      this.count = this.items.length
    },

    getUserByStatus(data){
      this.items = [];
        this.driversList.data.forEach(element => {
            if(data.status == element.work_status){
              this.items.push(element)
            }
        });
      this.count = this.items.length 
    }
  },
  watch: {
    pageNumber: function(){
      const from = (this.pageNumber - 1) * 10;
      const to = ((this.pageNumber - 1) * 10) + 10 ;
      this.items = this.driversList.data.slice(from, to);
    },
    driversList: function (){
        if(this.driversList.data){
          this.items = this.driversList.data.slice(0, 10);
          this.count = this.driversList.data.length
        }

    }
  },
  async created() {       
    await this.$store.dispatch("getDriversList");
    
    this.items = this.driversList.data.slice(0, 10);
    this.count = this.driversList.data.length   
  },
}

</script>
